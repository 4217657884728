import React, { useEffect } from "react"
import { graphql } from "gatsby"

// components
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import Landing from "../components/cfd-modeling/landing"
import WhatWeDo from "../components/cfd-modeling/whatWeDo"
import CaseStudy from "../components/case-study"

const EnergyRetrofits = ({ data }) => {
  useEffect(() => {}, [])

  return (
    <Layout
      headerLang={data?.langQuery?.siteMetadata?.en?.header}
      footerLang={data?.langQuery?.siteMetadata?.en?.footer}
      langType="en"
    >
      <SEO
        title="Building Commissioning"
        description="EEE performs Computational Fluid Dynamics Projects timely, gloabaly, and remotely."
      />
      <Landing
        lang={data?.langQuery?.siteMetadata?.en?.cfd?.landing}
        langType="en"
      />
      <div id="more-info">
        <WhatWeDo
          isoSRC={data?.isoSRC?.childImageSharp?.fluid}
          thermalSRC={data?.thermal3SRC?.childImageSharp?.fluid}
          contaminantsSRC={data?.thermalSRC?.childImageSharp?.fluid}
          lang={data?.langQuery?.siteMetadata?.en?.cfd?.models}
        />

        <CaseStudy data={data} />
      </div>
    </Layout>
  )
}

export default EnergyRetrofits

export const query = graphql`
  query {
    isoSRC: file(relativePath: { eq: "Isosurfaces.webp" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    thermalSRC: file(relativePath: { eq: "thermal-comfort.webp" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    thermal3SRC: file(relativePath: { eq: "thermal-comfort_3.webp" }) {
      childImageSharp {
        fluid(maxWidth: 350, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    langQuery: site {
      siteMetadata {
        en {
          header {
            commissioning
            modeling
            retrofits
            cfd
            projects
            contact
          }
          footer {
            contact
            navItems
          }
          cfd {
            landing {
              title
              body
            }
            models {
              title
              subTitles
              subTexts
            }
          }
        }
      }
    }
    allContentfulFeaturedProject(
      filter: {
        service: { name: { eq: "CFD Modeling" }, node_locale: { eq: "en-US" } }
      }
    ) {
      edges {
        node {
          id
          name
          summary {
            summary
          }
          previewImage {
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid
            }
            description
          }
        }
      }
    }
  }
`
